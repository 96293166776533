.tx-solr-search-form {
    margin-bottom: 1rem;
}
form {
    .input-group {
        .tx-solr-submit {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

.search-filter-block {
    .facet {
        border-top: 1px solid $gray-light;
        padding: 1.5rem 0 1rem;
        h4 {
            text-transform: uppercase;
        }
    }
    ul.search-filter {
        list-style: none;
        padding-left: 0;
        li {
            display: inline-block;
            margin-right: 0.5rem;
            a.btn-search {
                display: inline-block;
                margin: 0.25rem 0;
                border: 1px solid $gray-lighter;
                border-radius: 18px;
                padding: 0.25rem 0.7rem;
                color: $gray-darker;
                background-color: $gray-lighter;
                white-space: nowrap;
                transition: all .3s;

                &:hover,
                &.btn-active {
                    color: #fff;
                    background-color: $primary;
                    border: 1px solid $primary;
                }
                .icon-close {
                    &::before {
                        font-size: 1.2rem;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}

.search-results-block {
    margin-bottom: 4.5rem;

    .search-filter-block {
        &.collapse {
            @include media-breakpoint-up(md) {
                display: block;
                .close-filter {
                    display: none;
                }
            }
            &.show {
                @include media-breakpoint-down(md) {
                    .close-filter {
                        display: block;
                        text-align: right;
                    }

                    .mobile-overlay {
                        position: fixed;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        z-index: 1002;
                        background-color: rgba(0,0,0,0.6);
                        transition: background-color 0.3s;
                    }

                    .filter-block {
                        position: fixed;
                        top: 10px;
                        right: 10px;
                        bottom: 10px;
                        left: 10px;
                        z-index: 1003;
                        padding: 1rem;
                        overflow-y: scroll;
                        background-color: #fff;

                        &.filter-block--inline {
                            .facets {
                                display: block;
                            }

                            .facet {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
        .facet {
            .facet-option-list {
                ul {
                    list-style: none;
                    padding-left: 0;
                }
            }
        }
    }
    .result-infos {
        font-size: 0.875rem;
    }
    .search-results {
        .search-result-item {
            margin-bottom: 3rem;
            a {
                text-decoration: none;
            }
            .location {
                margin-top: 1rem;
                margin-bottom: 0.25rem;
                @include media-breakpoint-up(md) {
                    margin-top: 0;
                }
            }
            h5 {
                font-family: $font-family-headlines;
                font-size: 1.75rem;
                font-weight: $font-weight-normal;
                text-transform: uppercase;
                margin-bottom: 0.25rem;
            }
            .rating {
                margin-bottom: 1rem;
            }
            .offer-info {
                margin-bottom: 1rem;
            }
            .offer-price {
                font-family: $font-family-headlines;
                margin-bottom: 1rem;
                .btn.btn-primary {
                    text-transform: none;
                }
            }

            .card-wide {
                @extend .row;
                .card-location {
                    display: none;
                }
                .card-title {
                    font-size: 1.75rem;
                    font-weight: 300;
                    text-transform: uppercase;
                    margin: 0.5rem 0 0;
                }
                .card-body {
                    padding: 0;
                }
            }
            .card-wide-ta {
                .offer-img {
                    @extend .col-12;
                    @extend .col-md-4;
                    @extend .col-lg-6;
                    .favorite-icon {
                        display: none;
                    }
                }
                .card-content {
                    @extend .col-12;
                    @extend .col-md-8;
                    @extend .col-lg-6;

                }
            }

        }
    }
}

.card {
    &.card-offer {
        margin-bottom: 3rem;

        a {
            text-decoration: none;
        }

        .offer-img {
            img {
                @extend .card-img-top;
                height: auto;
            }
        }

        .card-body {
            padding: 1.5rem 0 0.25rem;

            .card-destination {
                margin-bottom: 2px;
            }
            .card-rating {
                margin-bottom: 1rem;
            }

            h5 {
                font-family: $font-family-headlines;
                font-size: 1.75rem;
                font-weight: $font-weight-normal;
                text-transform: uppercase;
            }
        }

        .card-footer {
            padding-top: 1rem;
            .offer-price {
                font-family: $font-family-headlines;
                font-size: 1.5rem;
            }
        }
    }
}
.offer-grid {
    .grid-item:last-of-type {
        .card.card-offer {
            margin-bottom: 2rem;
        }
    }
    @include media-breakpoint-up(sm) {
        .grid-item:nth-last-of-type(-n+2) {
            .card.card-offer {
                margin-bottom: 2rem;
            }
        }
    }
    @include media-breakpoint-up(md) {
        .grid-item:nth-last-of-type(-n+3) {
            .card.card-offer {
                margin-bottom: 2rem;
            }
        }
    }
}

.offer-img {
    position: relative;
    .favorite-icon {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        .circle-wrap {
            border-color: #fff;
            background-color: #fff;
            padding-top: 5px;
        }
        &.is-favorite {
            .circle-wrap {
                border-color: $underwaterblue;
                background-color: $underwaterblue;
                color: #fff;
            }
        }
    }
}

.solr-pagination {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    ul.pagination {
        align-items: center;
        justify-content: center;

        a {
            color: $gray-darker;
        }

        .page-item {
            color: $gray-darker;

            .page-link {
                margin-right: 4px;
                margin-left: 4px;
                padding: 0.15rem 0.6rem 0.2rem 0.6rem;
            }

            a.page-link {
                &:hover,
                &:focus {
                    border-radius: 50%;
                    color: $black;
                    background-color: $gray-light;
                }
            }

            &.active {
                .page-link {
                    z-index: 0;
                    border-radius: 50%;
                    color: $white;
                    background-color: $black;
                }
            }
        }
    }
}

.search-suggestions {
    position: relative;
    z-index: 999;
    display: none;

    .suggest-results {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 450px;
        max-height: 320px;
        border: 1px solid $gray-light;
        padding-left: 0;
        overflow: auto;
        background-color: $white;
        list-style: none;
        box-shadow: 4px 8px 8px rgb(0 0 0 / 0.3);

        .suggest-group-header {
            border-bottom: 1px solid $gray-lighter;
            padding: 0.25rem 0.5rem;
            background-color: $gray-light;
        }

        .suggest-item {
            border-bottom: 1px solid $gray-lighter;
            font-size: 0.875rem;

            a {
                display: block;
                padding: 0.25rem 0.5rem;
                text-decoration: none;
                cursor: pointer;

                &:hover,&:focus,&.focus {
                    background-color: $gray-lighter;
                }

                span.location {
                    display: block;
                    color: $gray-darker;
                    font-size: 0.75rem;
                }
            }
        }
    }
}


// loading animation
.solr-loading-wrap {
    .solr-loading {
        position: relative;

        .line {
            position: absolute;
            width: 100%;
            height: 2px;
            margin-top: -8px;
            border-radius: 20px;
            overflow: hidden;
            background-color: $white;

            &::before {
                position: absolute;
                left: -50%;
                width: 25%;
                height: 2px;
                border-radius: 20px;
                background-color: $gray-darker;
                animation: lineAnim 1s linear infinite;
                content: '';
            }
        }
    }
}
@keyframes lineAnim {
    0% {
        left: -40%;
    }

    50% {
        left: 20%;
        width: 80%;
    }

    100% {
        left: 100%;
        width: 100%;
    }
}