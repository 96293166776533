.accordion {
    margin-bottom: 3rem;
    .accordion-item {
        border-bottom: 1px solid $gray-dark;
    }
    .accordion-body {
        padding-top: 0;
    }
    .accordion-button {
        font-family: $font-family-headlines;
        font-size: 1.5rem;
        font-weight: $font-weight-normal;
        text-transform: uppercase;
    }
    .list-subheader {
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        margin-bottom: 0;
    }
}

.col-divinginfo-text {
    @extend .col-12;
    @extend .col-sm-6;
    @extend .col-md-8;
    @extend .order-1;
    @extend .order-sm-0;
    @extend .mt-2;
    @extend .mt-sm-0;
}
.col-divinginfo-rating {
    @extend .col-12;
    @extend .col-sm-6;
    @extend .col-md-4;
    @extend .order-0;
    @extend .order-sm-1;
}

.tripadvisor-rating {
    .ta-review-item {
        border-top: 1px solid $gray-dark;
        padding-top: 1rem;
        font-size: 0.875rem;
    }
}

.card {
    &.card-highlight,
    &.card-sustainability,
    &.card-mantarating {
        margin-bottom: 1.25rem;
        .card-body {
            padding: 1.5rem;
            .card-title {
                font-family: $font-family-blackdiamond;
                font-size: 2.5rem;
            }
        }
    }
    &.card-highlight {
        background-color: $rating-bg;
    }
    &.card-sustainability {
        background-color: $sustainability-bg;
    }
    &.card-mantarating {
        background-color: $mantarating-bg;
    }
}

ul.list-rating {
    list-style: none;
    padding-left: 0;
    .list-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
    }
}

.section {
    margin-bottom: 1.25rem;
    a {
        text-decoration: none;
    }
    &.section-bt {
        border-top: 1px solid $gray-dark;
        padding-top: 1.5rem;
    }
    &.section-buttons {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: flex-start;
        @include media-breakpoint-between(md, xxl) {
            flex-direction: column;
            .btn {
                margin-bottom: 0.5rem;
            }
        }

        .btn.btn-secondary {
            &.is-favorite {
                --bs-btn-color: #{$underwaterblue};
                --bs-btn-border-color: #{$underwaterblue};
                --bs-btn-hover-border-color: #{$underwaterblue};
                --bs-btn-active-border-color: #{$underwaterblue};
                background-color: $underwaterblue;
                color: #fff;
                .icon-favorite {
                    color: #fff;
                }
            }
        }

    }
    .price {
        font-family: $font-family-headlines;
        font-weight: 400;
        font-size: 1.25rem;
    }
}

.engage-link {
    margin-bottom: 1.25rem;
}