@import '@splidejs/splide/src/css/template/default';

.header-media {
    img {
        width: 100%;
        height: auto;
    }

    .single-media {
        position: relative;
    }

    .splide {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                position: relative;
            }
        }
    }

    .splide__arrow {
        background: #fff;
        border-radius: 0;
        height: 3em;
        width: 1.5em;
        opacity: 0.6;
        &.splide__arrow--prev {
            left: 0;
        }
        &.splide__arrow--next {
            right: 0;
        }
    }

    .text-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width:100%;
        height:100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .text-title {
            font-family: $font-family-blackdiamond;
            font-size: 2rem;
            line-height: 3rem;
            @include media-breakpoint-up(sm) {
                font-size: 3rem;
                line-height: 4rem;
            }
            @include media-breakpoint-up(md) {
                font-size: 4.625rem;
                line-height: 7rem;
            }
            font-weight: $font-weight-bold;
            color: #fff;
            text-align: center;
            text-shadow: 0 0 16px rgba(0, 0, 0, 0.32);
        }
    }
}

.splide-content-carousel {
    .carousel-arrow-box {
        position: relative;
        max-width: 60px;
        padding-top: 1rem;
        min-height: 48px;
        .splide__arrows {
            position: relative;
        }
        .splide__arrow {
            background: #fff;
            border: 1px solid $body-color;
            height: 1.5em;
            width: 1.5em;
            svg {
                height: .75em;
                width: .75em;
            }
            &.splide__arrow--prev {
                left: 0;
            }
            &.splide__arrow--next {
                right: 0;
            }
        }
    }
}
