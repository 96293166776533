/* stylelint-disable no-descending-specificity */
//
// Material styles for form control - form outline
//

.form-control {
    min-height: auto;
    padding-top: 4px;
    padding-bottom: 3.28px;
    transition: all 0.1s linear;

    &:focus {
        border-color: $primary;
        box-shadow: none;
        box-shadow: inset 0 0 0 1px $primary;
        transition: all 0.1s linear;
    }

    &.form-control-sm {
        font-size: 0.775rem;
        line-height: 1.5;
    }

    &.form-control-lg {
        border-radius: 0.25rem;
        line-height: 2.15;
    }
}

.form-outline {
    position: relative;

    .form-helper {
        position: absolute;
        width: 100%;
        color: #757575;
        font-size: 0.875em;

        .form-counter {
            text-align: right;
        }
    }

    .trailing {
        position: absolute;
        top: 50%;
        right: 10px;
        left: initial;
        transform: translateY(-50%);
        pointer-events: none;
    }

    .form-icon-trailing {
        padding-right: 2rem !important;
    }

    .form-control {
        min-height: auto;
        border: 0;
        padding-top: $input-padding-top;
        padding-right: $input-padding-right;
        padding-bottom: $input-padding-bottom;
        padding-left: $input-padding-left;
        background: transparent;
        transition: $input-transition;

        ~ .form-label {
            position: absolute;
            top: 0;
            left: $form-label-left;
            max-width: 90%;
            margin-bottom: 0;
            padding-top: $form-label-padding-top;
            overflow: hidden;
            color: $form-label-color;
            white-space: nowrap;
            text-overflow: ellipsis;
            transform-origin: 0 0;
            transition: $form-label-transition;
            pointer-events: none;
        }

        ~ .form-notch {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            width: 100%;
            max-width: 100%;
            height: 100%;
            text-align: left;
            pointer-events: none;

            div {
                box-sizing: border-box;
                border: $border-width solid;
                border-color: $form-notch-div-border-color;
                background: transparent;
                transition: $input-transition;
                pointer-events: none;
            }

            .form-notch-leading {
                top: 0;
                left: 0;
                width: $form-notch-leading-width;
                height: 100%;
                border-right: none;
                border-radius: $form-notch-leading-border-radius 0 0 $form-notch-leading-border-radius;
            }

            .form-notch-middle {
                flex: 0 0 auto;
                width: auto;
                max-width: calc(100% - #{$form-notch-middle-max-width});
                height: 100%;
                border-right: none;
                border-left: none;
            }

            .form-notch-trailing {
                flex-grow: 1;
                height: 100%;
                border-left: none;
                border-radius: 0 $form-notch-trailing-border-radius $form-notch-trailing-border-radius 0;
            }
        }

        &:not(.placeholder-active)::placeholder {
            opacity: 0;
        }

        &:focus,
        &.active {
            &::placeholder {
                opacity: 1;
            }
        }

        &:focus {
            box-shadow: none !important;
        }

        &:focus ~ .form-label,
        &.active ~ .form-label {
            transform: $input-focus-active-label-transform;
        }

        &:focus ~ .form-label {
            color: $input-focus-label-color;
        }

        &:focus ~ .form-notch .form-notch-middle,
        &.active ~ .form-notch .form-notch-middle {
            border-top: 1px solid transparent;
            border-right: none;
            border-left: none;
        }

        &:focus ~ .form-notch .form-notch-middle {
            border-color: $input-focus-border-color;
            border-top: 1px solid transparent;
            box-shadow: 0 1px 0 0 $input-focus-border-color;
        }

        &:focus ~ .form-notch .form-notch-leading,
        &.active ~ .form-notch .form-notch-leading {
            border-right: none;
        }

        &:focus ~ .form-notch .form-notch-leading {
            border-color: $input-focus-border-color;
            box-shadow: -1px 0 0 0 $input-focus-border-color, 0 1px 0 0 $input-focus-border-color,
                0 -1px 0 0 $input-focus-border-color;
        }

        &:focus ~ .form-notch .form-notch-trailing,
        &.active ~ .form-notch .form-notch-trailing {
            border-left: none;
        }

        &:focus ~ .form-notch .form-notch-trailing {
            border-color: $input-focus-border-color;
            box-shadow: 1px 0 0 0 $input-focus-border-color, 0 -1px 0 0 $input-focus-border-color,
                0 1px 0 0 $input-focus-border-color;
        }

        &:disabled,
        &.disabled,
        &[readonly] {
            background-color: $input-disabled-background-color;
        }

        &.form-control-lg {
            padding-right: $input-padding-right-lg;
            padding-left: $input-padding-left-lg;
            font-size: $input-font-size-lg;
            line-height: $input-line-height-lg;

            ~ .form-label {
                padding-top: $form-label-padding-top-lg;
            }

            &:focus ~ .form-label,
            &.active ~ .form-label {
                transform: $input-focus-active-label-transform-lg;
            }
        }

        &.form-control-sm {
            padding-top: $input-padding-top-sm;
            padding-right: $input-padding-right-sm;
            padding-bottom: $input-padding-bottom-sm;
            padding-left: $input-padding-left-sm;
            font-size: $input-font-size-sm;
            line-height: $input-line-height-sm;

            ~ .form-label {
                padding-top: $form-label-padding-top-sm;
                font-size: $form-label-font-size-sm;
            }

            &:focus ~ .form-label,
            &.active ~ .form-label {
                transform: $input-focus-active-label-transform-sm;
            }
        }
    }

    &.form-white {
        .form-control {
            color: $form-white-input-color;

            ~ .form-label {
                color: $form-white-label-color;
            }

            ~ .form-notch {
                div {
                    border-color: $form-white-notch-div-border-color;
                }
            }

            &:focus ~ .form-label {
                color: $form-white-input-focus-label-color;
            }

            &:focus ~ .form-notch .form-notch-middle {
                border-color: $form-white-input-focus-border-color;
                border-top: 1px solid transparent;
                box-shadow: 0 1px 0 0 $form-white-input-focus-border-color;
            }

            &:focus ~ .form-notch .form-notch-leading {
                border-color: $form-white-input-focus-border-color;
                box-shadow: -1px 0 0 0 $form-white-input-focus-border-color,
                    0 1px 0 0 $form-white-input-focus-border-color,
                    0 -1px 0 0 $form-white-input-focus-border-color;
            }

            &:focus ~ .form-notch .form-notch-trailing {
                border-color: $form-white-input-focus-border-color;
                box-shadow: 1px 0 0 0 $form-white-input-focus-border-color,
                    0 -1px 0 0 $form-white-input-focus-border-color,
                    0 1px 0 0 $form-white-input-focus-border-color;
            }

            &::placeholder {
                color: $form-white-placeholder-color;
            }

            &:disabled,
            &.disabled,
            &[readonly] {
                background-color: $form-white-disabled-bgc;
            }
        }
    }
}
