.header-media {
    position: relative;
    margin-bottom: 3rem;
    picture {
        img {
            width: 100%;
            height: auto;
        }
    }
    .header-media-links {
        position: absolute;
        left: 1.25rem;
        bottom: 1.25rem;
        ul {
            list-style: none;
            padding-left: 0;
            li {
                .nav-link {
                    border-radius: 20px;
                    background-color: #fff;
                    font-weight: $font-weight-bold;
                    margin-right: 1rem;
                }
            }
        }
    }
}

.slick-slide,
.slick-background {
    display: none;
}
