.footer {
    margin-top: 4.5rem;
    .bordertop {
        margin-top: 1rem;
        border-top: 1px solid $gray-light;
    }
    .specialistslogo {
        a {
            display: block;
            background-color: #000;
            margin-bottom: 3rem;
        }
        img {
            max-width: 250px;
            height: auto;
        }

    }
    .footer-navrow {
        margin-bottom: 2rem;
        ul {
            list-style: none;
            padding-left: 0;
            li {
                font-family: $font-family-headlines;
                font-size: 1.25rem;
                text-transform: uppercase;
                font-weight: $font-weight-bold;
                a {
                    text-decoration: none;
                }
                ul {
                    li {
                        font-family: $font-family-sans-serif;
                        font-size: 1rem;
                        text-transform: none;
                        font-weight: $font-weight-normal;
                    }
                }
                p {
                    font-family: $font-family-sans-serif;
                    font-size: 1rem;
                    text-transform: none;
                    font-weight: $font-weight-normal;
                    a {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .footer-partnerlinks {
        margin-bottom: 2rem;
        .partner-header {
            font-family: $font-family-headlines;
            font-size: 1.25rem;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
        }
        p {
            a {
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .footer-logos {
        margin-bottom: 2rem;
        .logo-col-left {
            text-align: center;
            @include media-breakpoint-up(md) {
                text-align: left;
            }
            a {
                display: block;
                margin-bottom: 1rem;

                @include media-breakpoint-up(md) {
                    display: inline-block;
                    margin-bottom: 0;
                }
            }
        }
        .logo-col-right {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                margin-left: 2rem;
            }

            a {
                display: block;
                margin-bottom: 1rem;
            }

            @include media-breakpoint-up(md) {
                flex-direction: row;
                justify-content: flex-end;

                a {
                    display: inline-block;
                    margin-bottom: 0;
                }
            }
        }
    }

    .bottom-row {
        padding-top: 1rem;
        .nav-link {
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
            padding-top: 0;
        }
        .meta-nav {
            display: flex;
            justify-content: center;
            @include media-breakpoint-up(lg) {
                justify-content: flex-start;
            }
            ul {
                flex-direction: column;
                li {
                    text-align: center;
                }
                @include media-breakpoint-up(sm) {
                    flex-direction: row;
                    li {
                        text-align: left;
                        &:first-of-type {
                            .nav-link {
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }
        .copyright {
            display: flex;
            justify-content: center;
            @include media-breakpoint-up(lg) {
                justify-content: flex-end;
            }
        }
    }

}

.floating-button {
    &.floating-button--bottomright {
        position: fixed;
        bottom: 1.5rem;
        right: 1.5rem;
        z-index: 5;

        .btn-consulting {
            background-color: $palmgreen;
            color: #fff;
            border-radius: 28px;
            font-size: 1.25rem;
            padding: 14px 14px 12px;
            span.label {
                display: none;
            }
        }
        @include media-breakpoint-up(md) {
            bottom: 2rem;
            right: 2rem;
            .btn-consulting {
                font-size: 1rem;
                padding: 14px 24px;
                span.label {
                    display: inline
                }

            }
        }

    }
}