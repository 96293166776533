.borderbox {
    border: 1px solid $bottlegreen;
    padding: 3rem 3rem 2rem;
    margin-bottom: 4.5rem;
    color: $bottlegreen;
    h6 {
        color: $bottlegreen;
    }
    .frame-default {
        margin-bottom: 0;
    }
}

.highlightbox {
    padding: 2rem 2rem 1rem;
    margin-bottom: 4.5rem;

    &.highlightbox--blue {
        background-color: $blue-bg;
    }
    &.highlightbox--green {
        background-color: $green-bg;
    }

    .frame-default {
        margin-bottom: 0;
    }
}
.col-sm-6 {
    >.highlightbox {
        margin-bottom: 2rem;
    }
}