.card {
    &.card-contact {
        background-color: $contact;
        color: $white;
        margin-bottom: 3rem;
        @include media-breakpoint-between(sm,md) {
            flex-direction: row;
        }
        a,
        a:hover {
            color: $white;
        }
        .card-img {
            img {
                width: 100%;
                height: auto;
            }
        }
        .card-content {
            padding: 1.25rem;
            .subtitle {
                font-weight: $font-weight-bold;
            }
        }
        .btn.btn-secondary {
            --bs-btn-color: #fff;
            --bs-btn-bg: transparent;
            --bs-btn-border-color: #fff;
            --bs-btn-hover-bg: transparent;
            --bs-btn-hover-border-color: #fff;
            --bs-btn-active-bg: transparent;
            --bs-btn-active-border-color: #fff;
            color: #fff;
        }
    }
}