h1,h2,h3,h4,h5 {
    font-family: $font-family-headlines;
    text-transform: uppercase;
    font-weight: $font-weight-normal;
}
h5 {
    font-weight: $font-weight-bold;
}
h6 {
    font-family: $font-family-blackdiamond;
    font-size: 40px;
    margin-bottom: 1rem;
    font-weight: $font-weight-bold;
}
