.nav-breadcrumbs {
    font-size: 0.875rem;
    margin-bottom: 1rem;
    li {
        padding-right: 0.375rem;
        .nav-link {
            padding: 0;
            display: inline;
        }
        &:after {
            // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
            font-family: 'iconmanta';
            -webkit-font-smoothing: antialiased;
            content: '\e908';
            display: inline-block;
            line-height: 1;
        }
        &:first-of-type {
            .nav-link {
                padding-left: 0
            }
        }
        &:last-of-type {
            font-weight: $font-weight-bolder;
            &:after {
                content: ''
            }
        }
    }
}