@import '../../../bootstrap/variables';

/* stylelint-disable scss/dollar-variable-pattern */
// Variables pro

// scss-docs-start zindex-stack pro
$zindex-sidenav: 1035 !default;
$zindex-sidenav-backdrop: 1034 !default;
$zindex-timepicker: 1065 !default;
$zindex-datepicker: 1065 !default;
$zindex-datepicker-container: 1066 !default;
$zindex-lightbox: 1100 !default;
$zindex-lightbox-toolbar: 1110 !default;
$zindex-popconfirm: 1080 !default;
$zindex-popconfirm-backdrop: 1070 !default;
$zindex-autocomplete-dropdown-container: 1065 !default;

// scss-docs-end zindex-stack pro

// Datepicker

$datepicker-toggle-right: -10px !default;
$datepicker-toggle-top: 50% !default;
$datepicker-toggle-focus-color: $primary !default;
$datepicker-backdrop-background-color: rgba(0, 0, 0, 0.4) !default;
$datepicker-dropdown-container-width: 328px !default;
$datepicker-dropdown-container-height: 380px !default;
$datepicker-dropdown-container-background-color: $white !default;
$datepicker-dropdown-container-border-radius: 0.5rem !default;
$datepicker-dropdown-container-box-shadow: $box-shadow-4 !default;
$datepicker-modal-container-transform: translate(-50%, -50%) !default;
$datepicker-modal-container-width: 328px !default;
$datepicker-modal-container-height: 512px !default;
$datepicker-modal-container-background-color: $white !default;
$datepicker-modal-container-border-radius: 0.6rem 0.6rem 0.5rem 0.5rem !default;
$datepicker-modal-container-box-shadow: $datepicker-dropdown-container-box-shadow !default;

// $datepicker-modal-container-z-index: 1072 !default;
$datepicker-modal-container-date-media-margin-top: 100px !default;
$datepicker-modal-container-day-cell-media-width: 32x !default;
$datepicker-modal-container-day-cell-media-height: $datepicker-modal-container-day-cell-media-width !default;
$datepicker-modal-container-media-width: 475px !default;
$datepicker-modal-container-media-height: 360px !default;
$datepicker-modal-container-media-day-cell-width: 36px !default;
$datepicker-modal-container-media-day-cell-height: $datepicker-modal-container-media-day-cell-width !default;
$datepicker-header-height: 120px !default;
$datepicker-header-padding-x: 24px !default;
$datepicker-header-background-color: $primary !default;
$datepicker-header-border-radius: 0.5rem 0.5rem 0 0 !default;
$datepicker-title-height: 32px !default;
$datepicker-title-text-font-size: 10px !default;
$datepicker-title-text-font-weight: 400 !default;
$datepicker-title-text-letter-spacing: 1.7px !default;
$datepicker-title-text-color: $white !default;
$datepicker-date-height: 72px !default;
$datepicker-date-text-font-size: 34px !default;
$datepicker-date-text-font-weight: 400 !default;
$datepicker-date-text-color: $white !default;
$datepicker-date-controls-padding-top: 10px !default;
$datepicker-date-controls-padding-x: 12px !default;
$datepicker-arrow-border-width: 5px !default;
$datepicker-arrow-margin-left: 5px !default;
$datepicker-arrow-transform: rotate(180deg) !default;
$datepicker-date-controls-color: rgba(0, 0, 0, 0.64) !default;
$datepicker-view-change-button-padding: 10px !default;
$datepicker-view-change-button-color: #666 !default;
$datepicker-view-change-button-font-weight: 500 !default;
$datepicker-view-change-button-font-size: 0.9rem !default;
$datepicker-view-change-button-border-radius: 10px !default;
$datepicker-view-change-button-state-background-color: #eee !default;
$datepicker-view-change-button-after-border-width: 5px !default;
$datepicker-view-change-button-after-margin-left: 5px !default;
$datepicker-arrow-controls-margin-top: 10px !default;
$datepicker-previous-button-width: 40px !default;
$datepicker-previous-button-height: $datepicker-previous-button-width !default;
$datepicker-previous-button-line-height: $datepicker-previous-button-height !default;
$datepicker-previous-button-color: rgba(0, 0, 0, 0.64) !default;
$datepicker-previous-button-margin-right: 24px !default;
$datepicker-previous-button-state-background-color: #eee !default;
$datepicker-previous-button-state-border-radius: 50% !default;
$datepicker-previous-button-after-margin: 15.5px !default;
$datepicker-previous-button-after-border-width: 2px !default;
$datepicker-previous-button-after-transform: translateX(2px) rotate(-45deg) !default;
$datepicker-next-button-width: 40px !default;
$datepicker-next-button-height: $datepicker-next-button-width !default;
$datepicker-next-button-line-height: $datepicker-next-button-height !default;
$datepicker-next-button-color: rgba(0, 0, 0, 0.64) !default;
$datepicker-next-button-margin-background-color: #eee !default;
$datepicker-next-button-state-border-radius: 50% !default;
$datepicker-next-button-after-margin: 15.5px !default;
$datepicker-next-button-after-border-width: 2px !default;
$datepicker-next-button-after-transform: translateX(-2px) rotate(45deg) !default;
$datepicker-view-padding-x: 12px !default;
$datepicker-table-width: 304px !default;
$datepicker-day-heading-width: 40px !default;
$datepicker-day-heading-height: $datepicker-day-heading-width !default;
$datepicker-day-heading-font-size: 12px !default;
$datepicker-day-heading-font-weight: 400 !default;
$datepicker-cell-disabled-color: #ccc !default;
$datepicker-cell-hover-background-color: #d3d3d3 !default;
$datepicker-cell-selected-background-color: $primary !default;
$datepicker-cell-selected-color: $white !default;
$datepicker-cell-focused-background-color: #eee !default;
$datepicker-cell-focused-selected-background-color: $primary !default;
$datepicker-cell-border-width: 1px !default;
$datepicker-cell-border-color: $black !default;
$datepicker-small-cell-width: 40px !default;
$datepicker-small-cell-height: $datepicker-small-cell-width !default;
$datepicker-small-cell-content-width: 36px !default;
$datepicker-small-cell-content-height: $datepicker-small-cell-content-width !default;
$datepicker-small-cell-content-line-height: $datepicker-small-cell-content-height !default;
$datepicker-small-cell-content-border-radius: 50% !default;
$datepicker-small-cell-content-font-size: 13px !default;
$datepicker-large-cell-width: 76px !default;
$datepicker-large-cell-height: 42px !default;
$datepicker-large-cell-content-width: 72px !default;
$datepicker-large-cell-content-height: 40px !default;
$datepicker-large-cell-content-line-height: $datepicker-large-cell-content-height !default;
$datepicker-large-cell-content-padding-y: 1px !default;
$datepicker-large-cell-content-padding-x: 2px !default;
$datepicker-large-cell-content-border-radius: 999px !default;
$datepicker-yearview-content-padding-x: $datepicker-view-padding-x !default;
$datepicker-footer-height: 56px !default;
$datepicker-footer-padding-x: $datepicker-yearview-content-padding-x !default;
$datepicker-footer-btn-background-color: $white !default;
$datepicker-footer-btn-color: $primary !default;
$datepicker-footer-btn-padding-x: 10px !default;
$datepicker-footer-btn-font-size: 0.8rem !default;
$datepicker-footer-btn-font-weight: 500 !default;
$datepicker-footer-btn-height: 40px !default;
$datepicker-footer-btn-line-height: $datepicker-footer-btn-height !default;
$datepicker-footer-btn-letter-spacing: 0.1rem !default;
$datepicker-footer-btn-border-radius: 10px !default;
$datepicker-footer-btn-margin-bottom: 10px !default;
$datepicker-footer-btn-state-background-color: #eee !default;

// Select
$form-outline-select-arrow-color: #000 !default;
$form-outline-select-arrow-font-size: 0.8rem !default;
$form-outline-select-arrow-top: 8px !default;
$form-outline-select-arrow-right: 9px !default;
$form-outline-select-valid-color: #00b74a !default;
$form-outline-select-invalid-color: #f93154 !default;
$form-outline-select-clear-btn-color: #000 !default;
$form-outline-select-clear-btn-font-size: 1rem !default;
$form-outline-select-clear-btn-top: 7px !default;
$form-outline-select-clear-btn-right: 27px !default;
$form-outline-select-clear-btn-focus-color: $primary !default;
$form-outline-select-sm-clear-btn-font-size: 0.8rem !default;
$form-outline-select-sm-clear-btn-top: 4px !default;
$form-outline-select-lg-clear-btn-top: 11px !default;
$form-outline-select-dropdown-container-z-index: $zindex-popconfirm-backdrop !default;
$form-outline-select-dropdown-bgc: #fff !default;
$form-outline-select-dropdown-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12) !default;
$form-outline-select-dropdown-min-width: 200px !default;
$form-outline-select-dropdown-transform: scaleY(0.8) !default;
$form-outline-select-dropdown-transition: none;
$form-outline-select-dropdown-open-transform: scaleY(1) !default;
$form-outline-select-dropdown-input-group-padding: 10px !default;
$form-outline-select-label-max-width: 80% !default;
$form-outline-select-label-active-transform: translateY(-1rem) translateY(0.1rem) scale(0.8) !default;
$form-outline-select-lg-label-active-transform: translateY(-1.25rem) translateY(0.1rem) scale(0.8) !default;
$form-outline-select-sm-label-active-transform: translateY(-0.83rem) translateY(0.1rem) scale(0.8) !default;
$form-outline-select-input-focused-color: #616161 !default;
$form-outline-select-label-color: $primary !default;
$form-outline-select-notch-border-width: 2px !default;
$form-outline-select-notch-border-color: $primary !default;
$form-outline-select-notch-transition: all 0.2s linear !default;
$form-outline-select-input-focused-arrow-color: $primary !default;
$form-outline-select-white-focus-arrow-color: #fff !default;
$form-outline-select-white-arrow-color: #fff !default;
$form-outline-select-white-clear-btn: #fff !default;
$form-outline-select-sm-arrow-top: 3px !default;
$form-outline-select-lg-arrow-top: 13px !default;
$form-outline-select-options-wrapper-scrollbar-width: 8px !default;
$form-outline-select-options-wrapper-scrollbar-height: 4px !default;
$form-outline-select-options-wrapper-scrollbar-border-bottom-right-radius: 4px !default;
$form-outline-select-options-wrapper-scrollbar-border-bottom-left-radius: 4px !default;
$form-outline-select-options-wrapper-scrollbar-thumb-height: 50px !default;
$form-outline-select-options-wrapper-scrollbar-thumb-bgc: #d8d8d8 !default;
$form-outline-select-options-wrapper-scrollbar-thumb-border-radius: 4px !default;
$form-outline-select-option-group-label-pl: 16px !default;
$form-outline-select-option-group-label-pr: 16px !default;
$form-outline-select-option-group-label-font-size: 1rem !default;
$form-outline-select-option-group-label-font-weight: 400 !default;
$form-outline-select-option-group-label-color: rgba(0, 0, 0, 0.54) !default;
$form-outline-select-option-group-select-option-pl: 26px !default;
$form-outline-select-option-color: rgba(0, 0, 0, 0.87) !default;
$form-outline-select-option-pl: 16px !default;
$form-outline-select-option-pr: 16px !default;
$form-outline-select-option-font-size: 1rem !default;
$form-outline-select-option-font-weight: 400 !default;
$form-outline-select-option-hover-not-disabled-bgc: $gray-lighter;
$form-outline-select-option-active-bgc: $gray-lighter;
$form-outline-select-option-selected-active-bgc: $body-color;
$form-outline-select-option-selected-disabled-color: $gray-dark;
$form-outline-select-option-selected-bgc: $gray-light;
$form-outline-select-option-disabled-color: $gray-dark;
$form-outline-select-option-text-form-check-input-mr: 10px !default;
$form-outline-select-option-secondary-text-font-size: 0.8rem !default;
$form-outline-select-option-secondary-text-color: #6c757d !default;
$form-outline-select-option-icon-width: 28px !default;
$form-outline-select-option-icon-height: 28px !default;
$form-outline-select-custom-content-padding: 16px !default;
$form-outline-select-no-results-pl: 16px !default;
$form-outline-select-no-results-pr: 16px !default;
$form-outline-select-white-arrow: #fff !default;
