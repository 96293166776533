.header-logo-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 14px 0;

    .navbar-brand {
        img.logo {
            width: 175px;
            height: auto;
            @include media-breakpoint-up(sm) {
                width: 279px;
            }
        }
    }

    .nav-lang {
        a.nav-lang-item {
            text-transform: uppercase;
            text-decoration: none;
            &.active {
                font-weight: 400;
            }
        }
        span {
            padding-left: 0.25rem;
            padding-right: 0.25rem;
        }
    }

    .service-bar {
        display: flex;
        justify-content: flex-end;

        .nav-header-service {
            li {
                .nav-link {
                    display: inline-flex;
                    align-self: center;
                    padding: 0.25rem 0.25rem;
                    @include media-breakpoint-up(sm) {
                        padding: 0.25rem 0.5rem;
                    }

                    i {
                        font-size: 20px;
                    }

                    @include media-breakpoint-up(sm) {
                        padding-right: 0.75rem;
                        padding-left: 0.75rem;
                    }

                    &.navbar-toggler {
                        padding: 0.25rem 0 0.25rem 0.25rem;
                        line-height: var(--bs-body-line-height);
                        cursor: pointer;

                        @include media-breakpoint-up(sm) {
                            padding: 0.25rem 0.75rem;
                        }
                    }
                }

                &.nav-item {
                    display: inline-flex;
                    &.nav-item--maintopic {
                        display: none;
                        padding-left: .75rem;
                        padding-right: .75rem;
                        @include media-breakpoint-up(lg) {
                            display: inline-flex;
                        }

                        .nav-link {
                            font-size: 1.125rem;
                            padding-left: 0;
                            padding-right: 0;
                            border-bottom: 1px solid transparent;
                            &:hover {
                                border-bottom: 1px solid $black
                            }
                        }

                        &.nav-item--maintopic-active {
                            .nav-link {
                                font-weight: 400;
                                border-bottom: 1px solid $black
                            }
                        }
                    }

                    &.nav-item--favorites,
                    &.nav-item--search {
                        padding-left: .75rem;
                        padding-right: .75rem;
                        .nav-link {
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }
                    &.nav-item--favorites {
                        .nav-link {
                            position: relative;
                            .badge {
                                position: absolute;
                                top: 0;
                                right: -6px;
                                display: inline-block;
                                border-radius: 1rem;
                                background-color: $underwaterblue;
                                font-size: 11px;
                                color: #fff;
                                padding: 4px 5px 3px;
                                text-align: center;
                                line-height: 1;
                            }
                        }
                    }

                    &.nav-item--menu {
                        >a {
                            padding-right: 0
                        }
                    }
                }
            }

            .dropdown-toggle::after {
                display: none;
            }
            .dropdown-menu {
                box-shadow: var(--bs-dropdown-box-shadow);
                .dropdown-item {
                    --bs-dropdown-item-padding-x: 1.25rem;
                }
                .nav-lang {
                    padding: 0.5rem 1.25rem;
                }
            }

            .nav-phone {
                padding: 0 1.25rem 0.5rem;
            }
        }
    }

    .phone-block {
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
        padding-top: 0.5rem;
        border-top: 1px solid $gray-light;
        i {
            font-size: 1.4rem;
            margin-right: 1rem;
        }
        span.label {
            font-size: 0.875rem;
        }
        a {
            font-weight: 400;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .offcanvas {
        a {
            cursor: pointer;
        }
        .offcanvas-body {
            padding-top: 1.5rem;
        }

        .navbar-brand {
            img.logo {
                width: 200px;
            }
        }

        .nav-main-mobile {
            .nav-item--maintopic {
                font-size: 1.25rem;
                a {
                    font-family: $font-family-headlines;
                    font-weight: $font-weight-bolder;
                    text-decoration: none;
                }
                &-active {
                    a {
                        text-decoration: underline;
                    }
                }
            }
            .nav-item {
                a {
                    --bs-nav-link-padding-x: 0;
                    --bs-nav-link-padding-y: 0.375rem;
                }
                &.nav-item-active {
                    a {
                        font-weight: $font-weight-bold;
                    }
                }
            }
        }

        .nav-service-mobile {
            margin-top: 1rem;
            border-top: 1px solid $gray-light;
            padding-top: 1rem;

            a {
                --bs-nav-link-padding-x: 0;
                --bs-nav-link-padding-y: 0.375rem;
            }

            .nav-lang {
                padding: 1rem 0 1rem;
            }
        }
        .nav-mobile-bottom {
            .nav-phone {
                flex: 0 0 100%;
            }
        }
    }
}


.navbar-main {
    border-top: 1px solid $gray-light;
    @include media-breakpoint-up(lg) {
        border-bottom: 1px solid $gray-light;
    }

    .navbar {
        .navbar-nav {
            li.nav-item {
                .nav-link {
                    padding-top: 0.25rem;
                    padding-bottom: 0.25rem;
                }

                &:first-of-type {
                    .nav-link {
                        padding-left: 0;
                    }
                }
            }
        }
    }
}

.search-bar {
    .quicksearch-suggestion {
        font-size: 0.875rem;

        strong {
            padding-right: 0.25rem;
        }

        a.search-item {
            padding: 0 0.25rem;
            text-decoration: none;
            cursor: pointer;
        }
    }
}