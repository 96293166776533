$icon-font: 'iconkuoni';
$icon-font-dir: '../fonts';
$icon-font-file: #{$icon-font-dir}/#{$icon-font};
$icon-font-src: url('#{$icon-font-file}.woff2') format('woff2'), url('#{$icon-font-file}.woff') format('woff');

@font-face {
    src: $icon-font-src;
    font-family: 'iconmanta';
    font-weight: normal;
    font-style: normal;
}

[class^='icon-']::before, [class*=' icon-']::before {
    display: inline-block;
    width: 1em;
    margin-right: 0.2em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;
    // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
    font-family: 'iconmanta' !important;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1em;
    text-align: center;
    text-transform: none;
    text-decoration: inherit;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: never;
}

.icon-grade-full:before {
    content: "\e91e";
}
.icon-grade-half:before {
    content: "\e91f";
}
.icon-grade-empty:before {
    content: "\e920";
}
.icon-double-arrow-right:before {
    content: "\e930";
}
.icon-double-arrow-left:before {
    content: "\e931";
}
.icon-add:before {
    content: "\e932";
}
.icon-minimize:before {
    content: "\e933";
}
.icon-thumb:before {
    content: "\e934";
}
.icon-circle-full:before {
    content: "\e935";
}
.icon-circle-half:before {
    content: "\e936";
}
.icon-circle-empty:before {
    content: "\e937";
}
.icon-done:before {
    content: "\e92f";
}
.icon-clipboard:before {
    content: "\e900";
}
.icon-clipboard-check:before {
    content: "\e901";
}
.icon-dot:before {
    content: "\e902";
}
.icon-star-full:before {
    content: "\e903";
}
.icon-star-half:before {
    content: "\e904";
}
.icon-menu:before {
    content: "\e905";
}
.icon-close:before {
    content: "\e906";
}
.icon-forward:before {
    content: "\e907";
}
.icon-next:before {
    content: "\e908";
}
.icon-previous:before {
    content: "\e909";
}
.icon-map:before {
    content: "\e90a";
}
.icon-place:before {
    content: "\e90b";
}
.icon-route:before {
    content: "\e90c";
}
.icon-calendar:before {
    content: "\e90d";
}
.icon-circle-add:before {
    content: "\e90e";
}
.icon-circle-remove:before {
    content: "\e90f";
}
.icon-chevron-left:before {
    content: "\e910";
}
.icon-chevron-right:before {
    content: "\e911";
}
.icon-expand-more:before {
    content: "\e912";
}
.icon-favorite:before {
    content: "\e913";
}
.icon-filter:before {
    content: "\e914";
}
.icon-ipaper:before {
    content: "\e915";
}
.icon-list:before {
    content: "\e916";
}
.icon-open-in-new:before {
    content: "\e917";
}
.icon-search:before {
    content: "\e918";
}
.icon-cancel-circle:before {
    content: "\e919";
}
.icon-expand-circle-down:before {
    content: "\e91a";
}
.icon-flight-takeoff:before {
    content: "\e91b";
}
.icon-flight-land:before {
    content: "\e91c";
}
.icon-group:before {
    content: "\e91d";
}
.icon-call:before {
    content: "\e921";
}
.icon-mail:before {
    content: "\e922";
}
.icon-video:before {
    content: "\e923";
}
.icon-chat:before {
    content: "\e924";
}
.icon-forum:before {
    content: "\e925";
}
.icon-photo:before {
    content: "\e926";
}
.icon-open-in-full:before {
    content: "\e927";
}
.icon-facebook:before {
    content: "\e928";
}
.icon-instagram:before {
    content: "\e929";
}
.icon-pinterest:before {
    content: "\e92a";
}
.icon-youtube:before {
    content: "\e92b";
}
.icon-linkedin:before {
    content: "\e92c";
}
.icon-whatsapp:before {
    content: "\e92d";
}
.icon-info:before {
    content: "\e92e";
}

