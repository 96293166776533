.tx-knfastbooking {
    .input-group {
        .input-group-text {
            padding: .675rem .5rem .375rem .75rem;
            background-color: #fff;

            i {
                font-size: 1.5rem;
            }
        }

        .form-floating {
            > .form-control,
            > .form-control-plaintext {
                padding-left: .5rem;
                padding-right: .5rem;
            }

            > .form-control:focus ~ label::after,
            > .form-control:not(:placeholder-shown) ~ label::after,
            > .form-control-plaintext ~ label::after,
            > .form-select ~ label::after {
                display: none;
            }

            .form-control:disabled {
                background-color: #fff;
            }

            select, input {
                border-left-width: 0;
            }

            &.mdb-select {
                .select-arrow {
                    display: none;
                }

                .select-input {
                    padding-top: 1.625rem;
                    padding-bottom: 0.5rem;
                    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23666%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e');
                    background-repeat: no-repeat;
                    background-position: right 0.75rem center;
                    background-size: 16px 12px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }

                .select-input.form-control[readonly]:not([disabled]) {
                    background-color: $white;
                }

                .form-outline {
                    .form-control {
                        padding-left: 0.5rem;

                        ~ .form-notch {
                            div {
                                border-color: $gray-dark;
                            }

                            .form-notch-leading {
                                border-left: none;
                                border-radius: 0;
                            }
                        }
                    }
                }

                .form-outline .form-control ~ .form-label.select-fake-value.active,
                .form-outline .form-control:focus ~ .form-label.select-fake-value.active,
                .form-outline .form-control.active ~ .form-label.select-fake-value.active {
                    transform: translateY(1.3rem) translateX(-.25em);
                    color: var(--bs-body-color)
                }

                > label {
                    transform: $fastbooking-form-floating-label-transform;
                    opacity: $form-floating-label-opacity;
                }

            }
        }
    }

    .form-floating {
        >.form-control:focus~label,
        >.form-control:not(:placeholder-shown)~label,
        >.form-control-plaintext~label,
        >.form-select~label {
            transform: $fastbooking-form-floating-label-transform;
        }
    }

    @include media-breakpoint-between(md,lg) {
        .fastbooking-container--sidebar {
            .input-group {
                .input-group-text {
                    padding: 0 0 0 4px;
                    i {
                        display: none;
                    }
                }
            }
        }
    }
}

.fastbooking-container {
    margin-bottom: 4.5rem;
    padding: 2.25rem 1.5rem;
    background-color: $booking-bg;

    @include media-breakpoint-up(md) {
        padding: 3rem 2.5rem;
    }
    @include media-breakpoint-up(xl) {
        padding: 4.5rem 6rem;
    }

    @include media-breakpoint-down(xs) {
        .search-button {
            text-align: right;
        }
    }

    &.fastbooking-container--sidebar {
        margin-bottom: 3rem;
        padding: 1.25rem 1.25rem;

        .search-button {
            text-align: right;
        }

    }
}

// mdb select
.select-dropdown-container {
    .select-option {
        color: $body-color;
        font-weight: $font-weight-normal;
        &.selected.active {
            background: #f1f1f1;
        }
    }
}

// for view when data-mdb-inline="false"
.datepicker-modal-container {
    .datepicker-title {
        display: none;
    }

    .datepicker-footer {
        .datepicker-clear-btn,
        .datepicker-cancel-btn {
            display: none;
        }

        .datepicker-ok-btn,
        .datepicker-ok-btn:hover,
        .datepicker-ok-btn:focus {
            border-radius: 4px;
            color: #fff;
            background-color: $black;
        }
    }
}
